import React from "react";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  User as FirebaseAuthUser,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

import logo from "./logo.svg";
import "./App.css";

const firebaseConfig = {
  apiKey: "AIzaSyANXbVyapzwNmh6rRuHIiLA_sW8DymGIfQ",
  authDomain: "shibuya-ab145.firebaseapp.com",
  projectId: "shibuya-ab145",
  storageBucket: "shibuya-ab145.appspot.com",
  messagingSenderId: "573695275071",
  appId: "1:573695275071:web:b306447685afcc92a2888a",
  measurementId: "G-XT46Y0ZW29",
};

initializeApp(firebaseConfig);

const auth = getAuth();
const provider = new GoogleAuthProvider();

const App = () => {
  const [authUser, setAuthUser] = React.useState<FirebaseAuthUser | null>(
    getAuth().currentUser
  );

  React.useEffect(() => {
    const unregisterAuthObserver = getAuth().onAuthStateChanged((user) => {
      setAuthUser(user);
    });

    return () => unregisterAuthObserver();
  }, []);

  const login = () => signInWithPopup(auth, provider);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        {authUser ? (
          <h4>Welcome back {authUser.email}</h4>
        ) : (
          <button onClick={login}>Login</button>
        )}
      </header>
    </div>
  );
};

export default App;
